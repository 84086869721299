import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { todayInTimezone, toISODateFormat } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useError } from 'restaurantAdmin/errors/useError';
import { isRestaurantClosed } from 'restaurantAdmin/reservations/apiHelpers';
import type { AdminAvailabilityData } from './apiHelpers';
import { getAvailabilities } from './apiHelpers';
import {
  calculateFilteredData,
  getAvailableTimes,
} from './conciergeAvailabilityDerivedState';
import {
  availabilityReducer,
  clearSelectedAvailability,
  incrementShowEarlierOffset,
  incrementShowLaterOffset,
  initialAvailabilityState,
  setAvailabilities,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from './conciergeAvailabilityReducer';

export interface ConciergeAvailabilityContextState {
  availableTimes: string[];
  clearSelectedAvailability: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: AdminAvailabilityData[];
  handleShowEarlier: () => void;
  handleShowLater: () => void;
  hasAvailabilitiesOnGivenDay: boolean;
  hasEarlier: boolean;
  hasLater: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isLoading: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: AdminAvailabilityData | null;
  selectedDate: Date;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string;
  setSelectedAvailability: (availability: AdminAvailabilityData) => void;
  setSelectedDate: (date: Date) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
}

const ConciergeAvailabilityContext =
  createContext<ConciergeAvailabilityContextState | null>(null);
ConciergeAvailabilityContext.displayName = 'ConciergeAvailabilityContext';

export const useConciergeAvailabilityContext = () =>
  useDefinedContext(ConciergeAvailabilityContext);

interface ConciergeAvailabilityContextProviderProps {
  children: ReactNode;
}

export const ConciergeAvailabilityContextProvider = ({
  children,
}: ConciergeAvailabilityContextProviderProps) => {
  const restaurant = useRestaurant();
  const [isClosedToday, setIsClosedToday] = useState(true);
  const [state, dispatch] = useReducer(
    availabilityReducer,
    initialAvailabilityState(restaurant.timezone),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
  } = state;

  const setError = useError();

  useEffect(() => {
    if (restaurant.id) {
      // setting initial date to today in restaurant's timezone
      setSelectedDate(new Date(todayInTimezone(restaurant.timezone)));
      const checkRestaurantIsClosed = async () => {
        try {
          const isRestaurantClosedResponse = await isRestaurantClosed(
            restaurant.id,
            todayInTimezone(restaurant.timezone),
          );

          setIsClosedToday(isRestaurantClosedResponse);
        } catch (e) {
          setError(e);
        }
      };

      void checkRestaurantIsClosed();
    }
  }, [restaurant.id]);

  const { isPending: isLoading } = useAbortEffect(async () => {
    if (restaurant.id) {
      // clear availabilities when fetch is in progress
      // prevent user from choosing an availability while fetching
      dispatch(setAvailabilities([]));
      const allAvailabilities = await getAvailabilities(
        restaurant.id,
        selectedDate,
      );
      void dispatch(setAvailabilities(allAvailabilities));
    }
  }, [selectedDate, restaurant]);

  const handleShowEarlier = () => {
    dispatch(incrementShowEarlierOffset());
  };

  const handleShowLater = () => {
    dispatch(incrementShowLaterOffset());
  };

  const {
    filteredAvailabilities,
    hasEarlier,
    hasLater,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state);

  const availableTimes = getAvailableTimes(state);

  const isSelectedDateToday =
    todayInTimezone(restaurant.timezone) === toISODateFormat(selectedDate);

  const value = useMemo(
    (): ConciergeAvailabilityContextState => ({
      availableTimes,
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      expandedAvailabilityListingIds,
      filteredAvailabilities,
      handleShowEarlier,
      handleShowLater,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasEarlier,
      hasLater,
      hasUnsupportedGuestCount,
      isClosedToday,
      isLoading,
      isSelectedDateToday,
      selectedAvailability,
      selectedDate,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: AdminAvailabilityData) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: Date) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string) => dispatch(setSelectedTime(time)),
    }),
    [isClosedToday, isLoading, state],
  );

  return (
    <ConciergeAvailabilityContext.Provider value={value}>
      {children}
    </ConciergeAvailabilityContext.Provider>
  );
};

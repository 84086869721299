import { offset } from '@floating-ui/react';
import { type SelectListboxSlotProps } from '@mui/base';
import { Option } from '@mui/base/Option';
import { type SelectRootSlotProps } from '@mui/base/Select';
import { Select } from '@mui/base/Select';
import cx from 'classnames';
import { type ForwardedRef, forwardRef, type JSX } from 'react';
import {
  type Control,
  type FieldPath,
  type FieldValues,
  type PathValue,
  type UseControllerProps,
} from 'react-hook-form';
import { useController } from 'react-hook-form';
import { FormListbox } from '@components/formListbox/FormListbox';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './ControlledFormSelect.scss';
import { FormTooltip } from './FormTooltip';
import { LayoutVariant } from './sharedTypes';
import { ValidationErrorMessage } from './ValidationErrorMessage';

export interface FormSelectOption {
  label: string | JSX.Element;
  value: string | number;
}

export interface ControlledFormSelectProps<
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
> {
  className?: string;
  control: Control<T>;
  defaultValue?: PathValue<T, Name>;
  disabled?: boolean;
  label: string;
  name: Name;
  options: FormSelectOption[];
  placeHolder?: string;
  rules?: UseControllerProps<T, Name>['rules'];
  tooltipText?: string;
  variant?: LayoutVariant;
  onFocus?: () => void;
  onSelect?: (value: any) => void;
}

// adds a chevron icon
const SelectButton = forwardRef(
  <TValue extends object, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, ownerState, ...rest } = props;
    return (
      <button type="button" {...rest} ref={ref}>
        {children}
        <Icon name="chevron" />
      </button>
    );
  },
);
SelectButton.displayName = 'SelectButton';

// wraps FormListBox and avoids forwarding MUI-Select-specific props
const SelectListbox = forwardRef(
  <OptionValue extends object, Multiple extends boolean>(
    props: SelectListboxSlotProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const { ownerState, ...attrs } = props;
    return <FormListbox {...attrs} ref={ref} />;
  },
);
SelectListbox.displayName = 'SelectListbox';

export const ControlledFormSelect = <
  T extends FieldValues = FieldValues,
  Name extends FieldPath<T> = FieldPath<T>,
>({
  className,
  control,
  label,
  name,
  options,
  tooltipText,
  rules,
  disabled = false,
  placeHolder = 'Select...',
  defaultValue,
  variant = LayoutVariant.Vertical,
  onFocus,
  onSelect,
}: ControlledFormSelectProps<T, Name>) => {
  const {
    field: { onChange, value: selectedValue = '' },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules,
    defaultValue,
  });

  const handleOnChange = (value: any) => {
    onSelect?.(value);
    onChange(value);
  };

  return (
    <div
      className={cx(
        {
          [styles.fieldContainer]: true,
          [styles[variant]]: true,
        },
        className,
      )}
    >
      <div className={styles.labelContainer}>
        <label
          htmlFor={`${name}-select`}
          className={cx({
            [typography.c2_20]: true,
            [styles.labelError]: !!error,
          })}
        >
          {label}
        </label>
        <FormTooltip label={name} text={tooltipText} />
      </div>
      <div className={styles.selectContainer}>
        <Select
          aria-errormessage={`${name}-error`}
          aria-invalid={!!error}
          id={`${name}-select`}
          disabled={disabled}
          onChange={(_, value) => {
            handleOnChange(value);
          }}
          onFocus={onFocus}
          className={cx({
            [typography.t1]: true,
            [styles.select]: true,
            [styles.selectError]: !!error,
          })}
          slotProps={{
            popup: {
              // open: true,
              disablePortal: true,
              middleware: [offset(8)],
              style: {
                zIndex: 10,
                width: '100%',
                boxShadow: '0 16px 16px 0 #272f3e66',
              },
            },
          }}
          value={selectedValue}
          slots={{
            root: SelectButton,
            listbox: SelectListbox,
          }}
          placeholder={placeHolder}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <ValidationErrorMessage error={error} label={label} name={name} />
      </div>
    </div>
  );
};

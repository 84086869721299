import cx from 'classnames';
import { useEffect, useState } from 'react';
import { type Control, useFieldArray, useWatch } from 'react-hook-form';
import { Icon } from '@components/icon/Icon';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import { type ListingFormData, type PricePointFormData } from '../types';
import { findOverlappingPricePointIndexes } from '../utils/listingUtils';
import styles from './PricePointFieldSet.scss';
import { PricePointModal } from './PricePointModal';

interface PricePointsListProps {
  control: Control<ListingFormData>;
}

export const PricePointFieldSet = ({ control }: PricePointsListProps) => {
  const {
    fields,
    append: addPricePoint,
    remove: removePricePoint,
    update: updatePricePoint,
  } = useFieldArray({
    control,
    name: 'pricePoints',
    // this is needed bc the library maintainer decided it was a good idea to use the key name 'id' for this as a default
    keyName: 'key',
  });

  const pricePoints = useWatch({
    control,
    name: 'pricePoints',
  });

  const [overlappingIndexes, setOverlappingIndexes] = useState<number[]>([]);

  const {
    isOpen: isPricePointModalOpen,
    open: openPricePointModal,
    close: closePricePointModal,
    data: pricePointModalData,
  } = useIsOpenWithData<{ pricePointIndex?: number }>();

  useEffect(() => {
    if (pricePoints?.length && pricePoints.length > 1) {
      const indexesWithOverlap = findOverlappingPricePointIndexes(pricePoints);
      setOverlappingIndexes(indexesWithOverlap);
    }
  }, [pricePoints]);

  return (
    <fieldset className={styles.container}>
      {fields.map(({ key, ...pricePoint }, index) => (
        <button
          key={key}
          className={styles.field}
          type="button"
          onClick={() => openPricePointModal({ pricePointIndex: index })}
          aria-label="Edit Price Point"
          title="Edit Price Point"
        >
          {overlappingIndexes.includes(index) && (
            <Icon
              name="alertCircle"
              className={styles.overlapIcon}
              ariaLabel="overlap indicator"
            />
          )}
          <div className={typography.h7m}>
            {`${ISOTimeTo12HourTime(pricePoint.startTime)}–${ISOTimeTo12HourTime(
              pricePoint.endTime,
            )}`}
          </div>
          <div className={cx(styles.price, typography.h7)}>
            {`$${pricePoint.price}`}
          </div>
          <div className={cx(styles.activeDays, typography.t1)}>
            {formatWeekDays(pricePoint.activeDays)}
          </div>
        </button>
      ))}
      <button
        className={styles.addButton}
        type="button"
        onClick={() => openPricePointModal({ pricePointIndex: undefined })}
      >
        Add Weekly Price Point
        <Icon name="plus" />
      </button>
      {isPricePointModalOpen && (
        <PricePointModal
          isOpen
          closeModal={closePricePointModal}
          pricePointIndex={pricePointModalData?.pricePointIndex}
          pricePoints={pricePoints}
          handleOnClickRemovePricePoint={() => {
            removePricePoint(pricePointModalData?.pricePointIndex);
            closePricePointModal();
          }}
          handleOnClickOkay={(data: PricePointFormData) => {
            const isEditing =
              pricePointModalData?.pricePointIndex !== undefined;

            if (isEditing) {
              updatePricePoint(pricePointModalData.pricePointIndex!, data);
            } else {
              addPricePoint(data);
            }

            closePricePointModal();
          }}
        />
      )}
    </fieldset>
  );
};

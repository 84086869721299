import { TZDateMini } from '@date-fns/tz';
import { useEffect, useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { errorToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import {
  getErrorResponseMessage,
  isSuccessResponse,
} from '@shared/types/apiHelpers';
import type { FloorPlanTableData } from '@shared/types/floorPlans';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { DateAndGuestCountFilterPill } from '../../components/dateAndGuestCountFilterPill/DateAndGuestCountFilterPill';
import { TimeFilterPill } from '../../components/timeFilterPill/TimeFilterPill';
import { useAdminAvailability } from '../../hooks/useAdminAvailability';
import { useAdminFloorPlan } from '../../hooks/useAdminFloorPlan';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { AdminAvailability } from './AdminAvailability';
import type { StrandedReservation } from './apiHelpers';
import { getAllUpcomingStrandedReservations, rescue } from './apiHelpers';
import {
  type OnRescueReservationCallback,
  RescueReservationConfirmationModal,
} from './RescueReservationConfirmationModal';
import { StrandedList } from './StrandedList';
import styles from './StrandedPage.scss';
import { StrandedSheet } from './StrandedSheet';

export const StrandedPage = () => {
  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useIsOpen();
  const {
    close: closeGuestCountAndDateFilter,
    isOpen: isGuestCountAndDateFilterOpen,
    open: openGuestCountAndDateFilter,
  } = useIsOpen();
  const {
    close: closeTimeFilter,
    isOpen: isTimeFilterOpen,
    open: openTimeFilter,
  } = useIsOpen();

  const [strandedReservations, setStrandedReservations] = useState<
    StrandedReservation[]
  >([]);
  const [selectedStrandedReservation, setSelectedStrandedReservation] =
    useState<StrandedReservation | null>(null);

  const { id: restaurantId, timezone } = useRestaurant();
  const {
    availabilities,
    availableTimes,
    handleShowEarlier,
    handleShowLater,
    hasEarlier,
    hasLater,
    selectedAvailability,
    selectedDate,
    selectedGuestCount,
    selectedTime,
    setSelectedAvailability,
    setSelectedDate,
    setSelectedGuestCount,
    setSelectedTime,
    refetchAvailabilities,
  } = useAdminAvailability();
  const { floorPlan, isLoading, fetchFloorPlan } = useAdminFloorPlan(true);

  const calculateIsHighlighted = (floorPlanTable: FloorPlanTableData) =>
    !!(
      selectedAvailability &&
      floorPlanTable.listings.find(
        ({ id }) => id === selectedAvailability.listing.id,
      )
    );
  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    floorPlanTablesRendererFactory({
      calculateIsHighlighted,
      tables: floorPlan?.floorPlanTables || [],
    });

  const fetchStrandedReservations = async () => {
    const result = await getAllUpcomingStrandedReservations(restaurantId);
    if (isSuccessResponse(result)) setStrandedReservations(result);
  };

  useEffect(() => {
    void fetchStrandedReservations();
  }, []);

  const handleSheetClose = () => setSelectedStrandedReservation(null);

  const handleSelectTime = (time: string) => {
    setSelectedTime(time);
    setSelectedAvailability(undefined);
  };

  const rescueReservation = async ({
    notifyGuest,
  }: OnRescueReservationCallback): Promise<void> => {
    if (!selectedStrandedReservation || !selectedAvailability) return;

    const response = await rescue({
      restaurantId,
      strandedReservationId: selectedStrandedReservation.id,
      time: selectedAvailability.time,
      listingId: selectedAvailability.listing.id,
      notifyGuest,
    });

    if (!response.ok) {
      const errorMessage = await getErrorResponseMessage(response);
      errorToast({ message: errorMessage });
    } else {
      setSelectedStrandedReservation(null);
    }

    void fetchStrandedReservations();
    void refetchAvailabilities();
    void fetchFloorPlan();
  };

  return (
    <>
      <PageHeader category="Reservations" title="Stranded Reservations" />
      <PageContent className={styles.pageContent}>
        <StrandedList
          onItemClick={setSelectedStrandedReservation}
          strandedReservations={strandedReservations}
          selectedStrandedReservation={selectedStrandedReservation}
        />
        <div className={styles.content}>
          <div className={styles.availabilityContainer}>
            <DateAndGuestCountFilterPill
              closeFilter={closeGuestCountAndDateFilter}
              isOpenFilter={isGuestCountAndDateFilterOpen}
              onFilterApply={() => setSelectedAvailability(undefined)}
              openFilter={() => {
                closeTimeFilter();
                openGuestCountAndDateFilter();
              }}
              selectedDate={selectedDate}
              selectedGuestCount={selectedGuestCount}
              setSelectedDate={setSelectedDate}
              setSelectedGuestCount={setSelectedGuestCount}
              minimumDate={TZDateMini.tz(timezone)}
            />
            <TimeFilterPill
              availableTimes={availableTimes}
              closeFilter={closeTimeFilter}
              isOpenFilter={isTimeFilterOpen}
              openFilter={() => {
                closeGuestCountAndDateFilter();
                openTimeFilter();
              }}
              onSelectTime={handleSelectTime}
              selectedTime={selectedTime}
            />
          </div>
          <div className={styles.layout}>
            {!isLoading && (
              <FloorPlan
                backgroundSrc={floorPlan.backgroundSrc}
                className={styles.floorPlan}
                floorPlanTablesRenderer={floorPlanTablesRenderer}
              />
            )}
            <AdminAvailability
              availabilities={availabilities}
              availabilityItemAction={
                <Button
                  isDisabled={!selectedStrandedReservation}
                  className={styles.reserveButton}
                  label="Reassign"
                  onClick={openConfirmationModal}
                  variant={ButtonVariants.Primary}
                />
              }
              handleShowEarlier={handleShowEarlier}
              handleShowLater={handleShowLater}
              hasEarlier={hasEarlier}
              hasLater={hasLater}
              selectedAvailability={selectedAvailability}
              selectedGuestCount={selectedGuestCount}
              setSelectedAvailability={setSelectedAvailability}
            />
          </div>
        </div>
        {selectedStrandedReservation && (
          <StrandedSheet
            onCloseClick={handleSheetClose}
            reservation={selectedStrandedReservation}
          />
        )}
        <RescueReservationConfirmationModal
          closeModal={closeConfirmationModal}
          isOpen={isConfirmationModalOpen}
          onRescueReservation={rescueReservation}
        />
      </PageContent>
    </>
  );
};

import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import {
  toISODateFormat,
  toShortWeekDateFormat,
} from '@shared/utils/dateFormatters';
import { useError } from 'restaurantAdmin/errors/useError';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { deleteRestaurantNote, saveRestaurantNote } from '../apiHelpers';
import styles from './DayNoteModal.scss';

export interface DayNoteModalProps {
  closeModal: () => void;
  dayNote: string;
  isOpen: boolean;
  reservationDate: Date;
}

interface DayNoteForm {
  note: string;
}

export const DayNoteModal = ({
  closeModal,
  dayNote,
  isOpen,
  reservationDate,
}: DayNoteModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const setError = useError();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<DayNoteForm>({
    defaultValues: {
      note: dayNote,
    },
  });

  const onSubmit = async (data: DayNoteForm): Promise<void> => {
    if (!reservationDate) return;

    if (data.note.trim() === '') {
      try {
        const response = await deleteRestaurantNote({
          date: toISODateFormat(reservationDate),
          restaurantId,
        });

        if (isSuccessResponse(response)) {
          closeModal();
        }
      } catch (error) {
        setError(error);
      }
    } else {
      try {
        const response = await saveRestaurantNote({
          date: toISODateFormat(reservationDate),
          note: data.note.trim(),
          restaurantId,
        });

        if (isSuccessResponse(response)) {
          closeModal();
        }
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="All administrators of this restaurant will be able to view and edit
    this&nbsp;note."
      title={`Note for ${toShortWeekDateFormat(reservationDate)}`}
    >
      <div className={styles.container}>
        <form aria-labelledby="dialogTitle" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="note">
            <span>Note</span>
            <textarea
              className={typography.c2}
              id="note"
              {...register('note')}
            />
          </label>
          <ModalActions>
            <Button
              label="Cancel"
              onClick={closeModal}
              variant={ButtonVariants.Tertiary}
            />
            <Button
              label="Save"
              type="submit"
              variant={ButtonVariants.Primary}
              isDisabled={isSubmitting}
            />
          </ModalActions>
        </form>
      </div>
    </Modal>
  );
};

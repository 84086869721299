import cx from 'classnames';
import { uniq } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
  type Control,
  type UseFormResetField,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { ControlledFormTableIconRadio } from '@components/formInputs/ControlledFormTableIconRadio';
import { ControlledFormTabRadio } from '@components/formInputs/ControlledFormTabRadio';
import type { SelectNumberOption } from '@components/formSelect/FormSelect';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { TURN_TIME_OPTIONS } from '@shared/types/turnTimes';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import type { ListingFormData } from '../types';
import { seatingTypeFromIsCommunal } from '../utils/listingUtils';
import styles from './AdvancedOptionsFieldSet.scss';

interface AdvancedOptionsFieldSetProps {
  control: Control<ListingFormData>;
  isNewListing: boolean;
  setValue: UseFormSetValue<ListingFormData>;
  resetField: UseFormResetField<ListingFormData>;
}
export const AdvancedOptionsFieldSet = ({
  control,
  isNewListing,
  setValue,
  resetField,
}: AdvancedOptionsFieldSetProps) => {
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);
  const [shouldAutoPopulate, setShouldAutoPopulate] = useState(isNewListing);
  const highlightedTables = useWatch({
    control,
    name: 'highlightedTables',
  });

  const getDefaultMetaData = () => ({
    name: highlightedTables
      .map((table) => table.name)
      .sort()
      .join(','),
    inventoryCount: String(highlightedTables.length),
    minimumGuests: String(
      Math.min(...highlightedTables.map((table) => table.minimumGuests)),
    ),
    maximumGuests: String(
      Math.max(...highlightedTables.map((table) => table.maximumGuests)),
    ),
    turnTime: Math.max(...highlightedTables.map((table) => table.turnTime)),
    iconName: highlightedTables[0].iconName,
  });

  useEffect(() => {
    if (shouldAutoPopulate) {
      if (!highlightedTables.length) {
        resetField('name');
        resetField('inventoryCount');
        resetField('minimumGuests');
        resetField('maximumGuests');
        resetField('turnTime');
        resetField('iconName');
      } else {
        const defaults = getDefaultMetaData();
        setValue('name', defaults.name);
        setValue('inventoryCount', defaults.inventoryCount);
        setValue('minimumGuests', defaults.minimumGuests);
        setValue('maximumGuests', defaults.maximumGuests);
        setValue('turnTime', defaults.turnTime);
        setValue('iconName', defaults.iconName);
      }
    }
  }, [highlightedTables]);

  const handleOnOpenAdvancedOptions = () => {
    setIsAdvancedOptionsOpen(true);
    setShouldAutoPopulate(false);
  };

  const renderContent = () => {
    if (isAdvancedOptionsOpen) {
      return <EditableAdvancedOptions control={control} />;
    }

    if (highlightedTables.length) {
      return <DefaultAdvancedOptions control={control} />;
    }

    return <EmptyAdvancedOptions />;
  };

  const isEditable = !isAdvancedOptionsOpen && !!highlightedTables.length;

  return (
    <fieldset className={styles.fieldset}>
      <h3 className={typography.t3}>advanced options</h3>
      {isEditable && (
        <IconButton
          className={cx(typography.t1, styles.editButton)}
          ariaLabel="Edit"
          iconName="pencil"
          showLabel
          onClick={handleOnOpenAdvancedOptions}
        />
      )}
      {renderContent()}
    </fieldset>
  );
};

const EmptyAdvancedOptions = () => (
  <p className={typography.c2_20}>
    Select at least one table to highlight in this listing to auto-fill the
    Advanced Options.
  </p>
);

interface DefaultAdvancedOptionsProps {
  control: Control<ListingFormData>;
}
const DefaultAdvancedOptions = ({ control }: DefaultAdvancedOptionsProps) => {
  const [
    iconName,
    name,
    isCommunal,
    inventoryCount,
    minimumGuests,
    maximumGuests,
    turnTime,
    interval,
  ] = useWatch({
    control,
    name: [
      'iconName',
      'name',
      'isCommunal',
      'inventoryCount',
      'minimumGuests',
      'maximumGuests',
      'turnTime',
      'interval',
    ],
  });

  return (
    <dl>
      <div>
        <div className={styles.displayIcon}>
          <Icon
            name={`${iconName}Selected`}
            ariaLabel={`${iconName}Selected`}
          />
        </div>
        <div>
          <dt>Staff Name</dt>
          <dd>{name}</dd>
        </div>
      </div>
      <div>
        <dt>Seating Type</dt>
        <dd>{seatingTypeFromIsCommunal(isCommunal)}</dd>
      </div>
      <div>
        <dt>{isCommunal ? 'Number of Seats' : 'Number of Tables'}</dt>
        <dd>{inventoryCount}</dd>
      </div>
      <div>
        <dt>Min - Max Guests</dt>
        <dd>{`${minimumGuests}-${maximumGuests}`}</dd>
      </div>
      <div>
        <dt>Turn time</dt>
        <dd>{`${turnTime} mins`}</dd>
      </div>
      <div>
        <dt>Interval</dt>
        <dd>{`${interval} mins`}</dd>
      </div>
    </dl>
  );
};

const INTERVAL_OPTIONS: SelectNumberOption[] = [
  {
    label: '15 mins',
    value: 15,
  },
  {
    label: '30 mins',
    value: 30,
  },
  {
    label: '45 mins',
    value: 45,
  },
  {
    label: '60 mins',
    value: 60,
  },
];
interface EditableAdvancedOptionsProps {
  control: Control<ListingFormData>;
}
const EditableAdvancedOptions = ({ control }: EditableAdvancedOptionsProps) => {
  const { maxReservationGuests } = useRestaurant();

  const [
    highlightedTables,
    minimumGuests,
    maximumGuests,
    inventoryCount,
    isCommunal,
  ] = useWatch({
    control,
    name: [
      'highlightedTables',
      'minimumGuests',
      'maximumGuests',
      'inventoryCount',
      'isCommunal',
    ],
  });

  const maxGuestRules = isCommunal
    ? {
        max: {
          message:
            maxReservationGuests < Number(inventoryCount)
              ? `The Max Guest count cannot exceed the configured setting of ${maxReservationGuests} guests`
              : `The Max Guest count cannot exceed the Number of Seats`,
          value: Math.min(maxReservationGuests, Number(inventoryCount)),
        },
        min: {
          message: 'Max Guest must be greater than or equal to Min Guest',
          value: minimumGuests,
        },
        required: true,
      }
    : {
        max: {
          message: `The Max Guest count cannot exceed the configured setting of ${maxReservationGuests} guests`,
          value: maxReservationGuests,
        },
        min: {
          message: 'Max Guest must be greater than or equal to Min Guest',
          value: minimumGuests,
        },
        required: true,
      };

  const seatingTypeSubText = isCommunal
    ? 'Communal seating allows guests to reserve a number of individual seats seats at a table.'
    : 'Table seating allows guests to reserve tables with a set number of seats.';

  const inventoryCountTooltip = isCommunal
    ? 'Dictates how many individual seats are available to be purchased for this listing. WARNING: The Number of Seats may dictate the maximum party size.'
    : 'Dictates how many reservations at a single time can be purchased for this listing. WARNING: A Number of Tables greater than the number of tables highlighted on the floorplan can yield to overbooking.';

  return (
    <>
      <ControlledFormTableIconRadio
        control={control}
        label="Display Icon"
        name="iconName"
        tableIconNames={uniq(highlightedTables.map((table) => table.iconName))}
      />
      <ControlledFormInput
        control={control}
        type="text"
        label="Staff Name"
        name="name"
        placeholder="2 Top (Outside)"
        tooltipText="The internal name for the Listing. Seen only by Admins."
        rules={{
          required: true,
        }}
      />
      <ControlledFormTabRadio
        control={control}
        name="isCommunal"
        label="Seating Type"
        options={['Table', 'Communal']}
      />
      <p className={cx(styles.seatingTypeToolTip, typography.t2)}>
        {seatingTypeSubText}
      </p>
      <ControlledFormInput
        control={control}
        type="number"
        label={isCommunal ? 'Number of Seats' : 'Number of Tables'}
        name="inventoryCount"
        tooltipText={inventoryCountTooltip}
        rules={{
          min: {
            message: `Must be at least 1`,
            value: 1,
          },
          required: true,
        }}
      />
      <ControlledFormInput
        control={control}
        type="number"
        label="Min Guest"
        name="minimumGuests"
        rules={{
          max: {
            message: `Min Guest must be less than or equal to Max Guest`,
            value: maximumGuests,
          },
          min: {
            message: `A reservation must seat at least 1 guest`,
            value: 1,
          },
          required: true,
        }}
      />
      <ControlledFormInput
        control={control}
        type="number"
        label="Max Guest"
        name="maximumGuests"
        rules={maxGuestRules}
      />
      <ControlledFormSelect
        control={control}
        label="Turn Time"
        name="turnTime"
        options={TURN_TIME_OPTIONS}
        tooltipText="An estimate of how long guests will usually sit at the table, plus the time it takes to clear and set the table for the next guest"
        rules={{
          required: true,
        }}
      />
      <ControlledFormSelect
        control={control}
        label="Interval"
        name="interval"
        options={INTERVAL_OPTIONS}
        tooltipText="How often an Availability is shown between its “start” and “end” times. For example, a Listing with a start time of 12pm, an end time of 2pm, and a 30 minute interval will generate Availabilities at 12pm, 12:30pm, 1pm, 1:30pm, and 2pm."
        rules={{
          required: true,
        }}
      />
    </>
  );
};

import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import styles from './CreateListingModals.scss';

export interface CreateListingWarningModalProps {
  isOpen: boolean;
  closeModal: () => void;
  message?: string;
  isDraft?: boolean;
  handleOnConfirmSubmit: () => void;
}

export const CreateListingWarningModal = ({
  isOpen,
  closeModal,
  message,
  isDraft,
  handleOnConfirmSubmit,
}: CreateListingWarningModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Create Listing Warning">
    <div className={styles.modalContent}>
      <p className={typography.c2}>{message}</p>
      <p className={typography.c2}>Are you sure you want to proceed?</p>
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label={isDraft ? 'Draft Anyway' : 'Publish Anyway'}
          onClick={handleOnConfirmSubmit}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </div>
  </Modal>
);

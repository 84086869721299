import type { CancellationPolicy } from '@shared/reservations/types';
import { type ApiResponse } from '@shared/types/apiHelpers';
import { type RestaurantStatus } from '@shared/types/restaurants';
import { api } from '../../api';
import API from '../../api/apiClient';

export interface AdminRestaurant {
  address: string;
  cancellationPolicy: CancellationPolicy | null;
  city: string;
  coverLimit: number | null;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: number;
  name: string;
  nameSlug: string;
  pacingControls: {
    pacingLimit: number;
    pacingWindow: number;
  } | null;
  phone: string;
  postalCode: string;
  schedule: number;
  serviceWindows: {
    id: string;
    startDate: string;
    endDate: string;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  state: string;
  status: RestaurantStatus;
  street: string;
  timezone: string;
  website: string;
}

export type RestaurantAdminStatus = 'active' | 'inactive' | 'invited';

export interface Admin {
  email: string;
  fullName: string;
  id: string;
  status: RestaurantAdminStatus;
}

export const deactivateAdmin = async (
  restaurantId: string,
  adminId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/admins/${adminId}/deactivate`);

export const getAdminRestaurant = async (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ApiResponse<AdminRestaurant>> => {
  const response = await API.get(`/restaurants/${restaurantId}`, { signal });

  return response.json();
};

export const getAdmins = async (
  restaurantId: string,
): Promise<ApiResponse<Admin[]>> => {
  const response = await API.get(`/restaurants/${restaurantId}/admins`);
  return response.json();
};

export const inviteTeamMember = async (
  restaurantId: string,
  emails: string[],
): Promise<ApiResponse<object>> => {
  const response = await API.post(`/restaurants/${restaurantId}/admins`, {
    emails,
  });

  if (response.ok) return {};

  return response.json();
};

export const resendInvite = async (
  restaurantId: string,
  email: string,
): Promise<Response> =>
  API.post(`/restaurants/${restaurantId}/resend-invite`, {
    email,
  });

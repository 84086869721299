import { api } from 'restaurantAdmin/api';

export interface RestaurantMetadata {
  id: string;
  name: string;
  postalCode: string;
  timezone: string;
  maxReservationGuests: number;
  coverLimit: number | null;
  pacingControls: { pacingLimit: number; pacingWindow: number } | null;
}

export type GuestTagCategory =
  | 'allergies'
  | 'custom'
  | 'dietary restrictions'
  | 'guest attributes'
  | 'other';

export interface GuestTag {
  category: GuestTagCategory;
  id: string;
  title: string;
  createdBy: string;
}

export const getRestaurants = async (): Promise<RestaurantMetadata[]> =>
  api.get('/restaurants/');

export const getRestaurantTags = async (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<GuestTag[]> =>
  api.get(`/restaurants/${restaurantId}/tags`, { signal });

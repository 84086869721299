import { TZDateMini } from '@date-fns/tz';
import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import {
  getRestaurantTags,
  type GuestTag,
} from 'restaurantAdmin/restaurants/apiHelpers';
import { useRestaurant } from '../../../context/useRestaurant';
import { getOccupants, type OccupantsOccupant } from '../apiHelpers';
import {
  isSelectedReservationDateToday,
  occupantPositionValues,
  selectedOccupant,
} from './occupantDerivedState';
import {
  occupantReducer,
  type OccupantState,
  resetView,
  selectDate,
  selectNextDay,
  selectNextOccupant,
  selectOccupant,
  selectPreviousDay,
  selectPreviousOccupant,
  selectToday,
  updateGuestTagFilters,
  updateOccupants,
  updateRestaurantTags,
} from './occupantReducer';

export interface OccupantContextState {
  clearOccupant: () => void;
  guestTagFilters: GuestTag[];
  handleOnClickNextOccupant: () => void;
  handleOnClickPreviousOccupant: () => void;
  handleOnSelectOccupant: (occupantId: string) => void;
  handleTodayClick: () => void;
  isToday: boolean;
  occupantPositionValues: OccupantPositionValues;
  occupants: OccupantsOccupant[];
  onCalendarChange: (date: Date) => void;
  onGuestTagFilterChange: (newFilters: GuestTag[]) => void;
  refreshOccupants: () => void;
  reservationDate: Date;
  restaurantTags: GuestTag[];
  selectedOccupant: OccupantsOccupant | undefined;
  setNextDay: () => void;
  setPreviousDay: () => void;
}

export interface OccupantPositionValues {
  count: number;
  position: number;
}

const OccupantContext = createContext<OccupantContextState>(
  {} as OccupantContextState,
);

export const useOccupantContext = (): OccupantContextState =>
  useContext(OccupantContext);

export const OccupantContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // context hooks
  const restaurant = useRestaurant();

  // local state
  const initialState: OccupantState = {
    reservationDate: TZDateMini.tz(restaurant.timezone),
    selectedIndex: -1,
    occupants: [],
    restaurantTags: [],
    guestTagFilters: [],
  };
  const [state, dispatch] = useReducer(occupantReducer, initialState);
  const { occupants, reservationDate, restaurantTags, guestTagFilters } = state;

  const clearOccupant = () => dispatch(resetView());
  const handleOnClickNextOccupant = () => dispatch(selectNextOccupant());
  const handleOnClickPreviousOccupant = () =>
    dispatch(selectPreviousOccupant());
  const handleOnSelectOccupant = (occupantId: string) =>
    dispatch(selectOccupant(occupantId));
  const handleTodayClick = (): void =>
    dispatch(selectToday(restaurant.timezone));
  const setPreviousDay = () => dispatch(selectPreviousDay());
  const setNextDay = () => dispatch(selectNextDay());
  const onCalendarChange = (date: Date) => dispatch(selectDate(date));
  const onGuestTagFilterChange = (filters: GuestTag[]) => {
    dispatch(updateGuestTagFilters(filters));
    fetchOccupants(filters);
  };

  const fetchOccupants = (filters: GuestTag[] = []) => {
    void (async () => {
      const response = await getOccupants(
        restaurant.id,
        toISODateFormat(reservationDate),
        filters,
      );
      if (isSuccessResponse(response)) {
        dispatch(updateOccupants(response));
      }
    })();
  };

  const fetchRestaurantGuestTags = () => {
    void (async () => {
      const currentRestaurantTags = await getRestaurantTags(restaurant.id);

      dispatch(updateRestaurantTags(currentRestaurantTags));
    })();
  };

  useEffect(() => {
    fetchOccupants();
    fetchRestaurantGuestTags();
  }, [restaurant.id, reservationDate]);

  const value = useMemo<OccupantContextState>(
    () => ({
      clearOccupant,
      guestTagFilters,
      handleOnClickNextOccupant,
      handleOnClickPreviousOccupant,
      handleOnSelectOccupant,
      handleTodayClick,
      isToday: isSelectedReservationDateToday(state)(restaurant.timezone),
      occupantPositionValues: occupantPositionValues(state),
      occupants,
      onCalendarChange,
      onGuestTagFilterChange,
      refreshOccupants: fetchOccupants,
      reservationDate,
      restaurantTags,
      selectedOccupant: selectedOccupant(state),
      setNextDay,
      setPreviousDay,
    }),
    [restaurant.timezone, state],
  );

  return (
    <OccupantContext.Provider value={value}>
      {children}
    </OccupantContext.Provider>
  );
};

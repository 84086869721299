import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import {
  getWaitList,
  type WaitListEntry,
} from 'restaurantAdmin/reservations/service/sidePanel/waitList/apiHelpers';
import { useRestaurant } from '../../../../../context/useRestaurant';

export interface WaitListContextState {
  waitListEntries: WaitListEntry[];
  selectedWaitListEntry: WaitListEntry | undefined;
  fetchWaitListEntries: () => void;
  setSelectedWaitListEntryIndex: (index: number) => void;
}

const WaitListContext = createContext<WaitListContextState>(
  {} as WaitListContextState,
);

export const useWaitListContext = (): WaitListContextState =>
  useContext(WaitListContext);

export const WaitListContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();

  const [selectedWaitListEntryIndex, setSelectedWaitListEntryIndex] =
    useState<number>(-1);

  const { data: waitListEntries = [], rerun: fetchWaitListEntries } =
    useAbortEffect(() => getWaitList({ restaurantId }), [restaurantId]);

  const selectedWaitListEntry = waitListEntries[selectedWaitListEntryIndex];

  const value = useMemo(
    () => ({
      waitListEntries,
      setSelectedWaitListEntryIndex,
      fetchWaitListEntries,
      selectedWaitListEntry,
    }),
    [waitListEntries, selectedWaitListEntryIndex],
  );

  return (
    <WaitListContext.Provider value={value}>
      {children}
    </WaitListContext.Provider>
  );
};

import type { FeatureConfig } from '@shared/context/FeatureFlagContext';
import type { Environment } from '@shared/environments';

export type RestaurantAdminFeatureFlag =
  | 'testFlag'
  | 'serverSectionsFlag'
  | 'listingCreationVelocity';

export const featureFlagConfig: Readonly<
  Record<Environment, FeatureConfig<RestaurantAdminFeatureFlag>>
> = Object.freeze({
  development: {
    testFlag: true,
    serverSectionsFlag: true,
    listingCreationVelocity: true,
  },
  ci: {
    testFlag: true,
    serverSectionsFlag: true,
    listingCreationVelocity: true,
  },
  staging: {
    testFlag: true,
    serverSectionsFlag: true,
    listingCreationVelocity: true,
  },
  demo: {
    testFlag: false,
    serverSectionsFlag: true,
    listingCreationVelocity: false,
  },
  production: {
    testFlag: false,
    serverSectionsFlag: true,
    listingCreationVelocity: false,
  },
});

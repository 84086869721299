import { Kebab } from '@components/kebab/Kebab';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { getErrorResponseMessage } from '@shared/types/apiHelpers';
import { useError } from 'restaurantAdmin/errors/useError';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, resendInvite } from './apiHelpers';
import { DeactivateAdminConfirmationModal } from './DeactivateAdminConfirmationModal';

export interface TeamKebabProps {
  admin: Admin;
  refetchRestaurantAdmins: () => void;
}

export const TeamKebab = ({
  admin,
  refetchRestaurantAdmins,
}: TeamKebabProps) => {
  const restaurant = useRestaurant();
  const setError = useError();
  const { isOpen, open, close } = useIsOpen();

  const handleResendInvite = async () => {
    try {
      const response = await resendInvite(restaurant.id, admin.email);

      if (response.ok) {
        successToast({ message: 'The invite was re-sent successfully' });
      } else {
        const errorMessage = await getErrorResponseMessage(response);
        errorToast({ message: errorMessage });
      }
    } catch (error) {
      setError(error);
    }
  };

  const options = [
    {
      disabled: admin.status !== 'active',
      label: 'Deactivate',
      onClick: open,
    },
    {
      disabled: admin.status !== 'invited',
      label: 'Resend Invite',
      onClick: handleResendInvite,
    },
  ];

  return (
    <>
      <Kebab options={options} />
      <DeactivateAdminConfirmationModal
        admin={admin}
        closeModal={close}
        isOpen={isOpen}
        refetchRestaurantAdmins={refetchRestaurantAdmins}
      />
    </>
  );
};

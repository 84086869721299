import cx from 'classnames';
import { useMemo } from 'react';
import { parseToShortDateRange } from '@shared/utils/dateFormatters';
import { formatWeekDays } from '@shared/utils/weekDayFormatters';
import { centsToWholeDollar } from '@utils/currency';
import { ISOTimeAddMinutes, ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { Listing } from '../apiHelpers';
import styles from './ListingCard.scss';

interface ListingCardProps {
  listing: Listing;
  onClick?: () => void;
}

export const ListingCard = ({ listing, onClick }: ListingCardProps) => {
  const {
    endDate,
    endTime,
    id,
    name,
    price,
    repeat,
    startDate,
    startTime,
    turnTime,
  } = listing;

  const weekDaysString = useMemo(() => formatWeekDays(repeat), [repeat]);
  return (
    <li
      aria-labelledby={`listing-card-${id}-name`}
      className={styles.main}
      onClick={onClick}
    >
      <div className={styles.row1}>
        <div className={styles.heading}>
          <div
            className={cx(styles.name, typography.h5)}
            id={`listing-card-${id}-name`}
          >
            {name}
          </div>
          <div className={typography.t2}>
            {parseToShortDateRange(startDate, endDate)}
          </div>
        </div>
      </div>
      <div className={styles.row2}>
        <div>
          <div className={styles.daysAndPrice}>
            <div className={typography.h7m}>{weekDaysString}</div>
            <div className={cx(typography.h7, styles.price)}>
              {centsToWholeDollar(price)}
            </div>
          </div>
          <div className={cx(typography.t1, styles.timeRange)}>
            <span>{`${ISOTimeTo12HourTime(startTime)}–${ISOTimeTo12HourTime(endTime)}`}</span>{' '}
            <span>
              (last turn ends{' '}
              {ISOTimeTo12HourTime(ISOTimeAddMinutes(endTime, turnTime))})
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

import { useLocation, useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { reportAppError } from '@shared/reportAppError';
import {
  checkListingHasConflicts,
  type Listing,
  publishListing,
} from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { getDestinationPath } from '../utils/listingUtils';
import styles from './PublishListingConfirmationModal.scss';

export interface PublishListingConfirmationModalProps {
  closeModal: () => void;
  isOpen: boolean;
  listing: Listing;
}

export const PublishListingConfirmationModal = ({
  closeModal,
  isOpen,
  listing,
}: PublishListingConfirmationModalProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setSelectedListingId, setSelectedDate } = useListingsContext();

  const { data: hasConflicts, isPending: isLoading } = useAbortEffect(
    async (signal) => {
      if (!isOpen) {
        return undefined;
      }
      const response = await checkListingHasConflicts(
        listing.restaurantId,
        listing.id,
        signal,
      );
      return response.hasConflicts;
    },
    [isOpen],
  );

  const onConfirmPublishListing = () => {
    void (async () => {
      try {
        await publishListing(listing.restaurantId, listing.id);
        setSelectedListingId(listing.id);
        setSelectedDate(listing.startDate);
        navigate(getDestinationPath(pathname, 'published'));
      } catch (e) {
        if (e instanceof ApiError) {
          errorToast({ message: e.message });
        }
        reportAppError(e);
      }
    })();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen && !isLoading}
      onClose={closeModal}
      title="Publish Listing"
      subtitle="Publishing this listing will make it Live, meaning guests can make reservations for this listing immediately."
    >
      {hasConflicts && (
        <p className={styles.warning}>
          Warning: The inventory for this listing overlaps with that of another
          listing. This allows double-booking of one or more tables to occur.
          Are you sure you want to proceed?
        </p>
      )}
      <ModalActions>
        <Button
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Publish Listing"
          onClick={onConfirmPublishListing}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};

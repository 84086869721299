import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { IconButton } from '@components/iconButton/IconButton';
import { isSuccessResponse } from '@shared/types/apiHelpers';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { DateSelector } from 'restaurantAdmin/components/dateSelector/DateSelector';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { getRestaurantNote } from '../apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';
import { DayNoteModal } from './DayNoteModal';
import { OccupantMetrics } from './OccupantMetrics';
import styles from './OccupantsPageHeader.scss';
import { OccupantsPrintDropdown } from './OccupantsPrintDropdown';

const useDayNote = (restaurantId: string, reservationDate: Date) => {
  const [dayNote, setDayNote] = useState('');
  const [isDayNoteLoading, setIsLoading] = useState(false);

  const fetchRestaurantNote = useCallback(async () => {
    if (!reservationDate) return;
    setIsLoading(true);
    const response = await getRestaurantNote({
      restaurantId,
      date: toISODateFormat(reservationDate),
    });
    if (isSuccessResponse(response)) {
      setDayNote(response.note);
    }
    setIsLoading(false);
  }, [restaurantId, reservationDate]);

  useEffect(() => {
    void fetchRestaurantNote();
  }, [reservationDate, restaurantId]);

  return {
    dayNote,
    refetch: fetchRestaurantNote,
    isLoading: isDayNoteLoading,
  };
};

export const OccupantsPageHeader = () => {
  const [isDayNoteModalOpen, setIsDayNoteModalOpen] = useState(false);
  const restaurant = useRestaurant();
  const {
    reservationDate,
    handleTodayClick,
    isToday,
    onCalendarChange,
    setNextDay,
    setPreviousDay,
  } = useOccupantContext();

  const {
    dayNote,
    refetch: refetchDayNote,
    isLoading: isDayNoteLoading,
  } = useDayNote(restaurant.id, reservationDate);

  const handleDayNoteClick = () => setIsDayNoteModalOpen(true);

  const hasNote = Boolean(dayNote);

  const noteButtonAriaLabel = hasNote
    ? "View/edit this day's note"
    : 'Add a note about this day';

  if (isDayNoteLoading) return null;

  return (
    <div className={styles.container}>
      <DateSelector
        handleOnClickToday={handleTodayClick}
        isToday={isToday}
        handleOnChangeCalendarDate={onCalendarChange}
        selectedDate={reservationDate}
        setNextDay={setNextDay}
        setPreviousDay={setPreviousDay}
      />
      <OccupantMetrics />
      <div className={styles.actions}>
        <IconButton
          ariaLabel={noteButtonAriaLabel}
          className={cx({
            [styles.noteButton]: true,
            [styles.hasNote]: Boolean(dayNote),
          })}
          iconName="note"
          onClick={handleDayNoteClick}
        />
        <OccupantsPrintDropdown date={toISODateFormat(reservationDate)} />
      </div>
      <DayNoteModal
        closeModal={() => {
          setIsDayNoteModalOpen(false);
          void refetchDayNote();
        }}
        dayNote={dayNote}
        isOpen={isDayNoteModalOpen}
        reservationDate={reservationDate}
      />
    </div>
  );
};

import { TZDateMini } from '@date-fns/tz';
import { addHours, eachMinuteOfInterval } from 'date-fns';
import { filter, findIndex, uniq } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { createReferenceDate } from '@shared/utils/createReferenceDate';
import { todayInTimezone } from '@shared/utils/dateFormatters';
import { dateToISOTime } from '@utils/time';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useError } from 'restaurantAdmin/errors/useError';
import { isRestaurantClosed } from 'restaurantAdmin/reservations/apiHelpers';
import type { AdminAvailabilityData } from '../reservations/concierge/apiHelpers';
import { getAvailabilities } from '../reservations/concierge/apiHelpers';

const DEFAULT_GUEST_COUNT = 2;
const DEFAULT_SELECTED_TIME = '00:00:00';
const DEFAULT_SHOW_MORE_OFFSET = 0;
const DEFAULT_TIME_WINDOW_IN_HOURS = 2;
const MIN_START_INDEX = 0;
const SHOW_MORE_OFFSET = 20;

interface AvailabilityTimeAndGuestCountLimits {
  guestCount: number;
  time: string;
}

const getAvailableTimesForGuestCount = (
  availabilities: AvailabilityTimeAndGuestCountLimits[],
  guestCount: number,
): string[] =>
  uniq(
    filter(
      availabilities,
      (item: AvailabilityTimeAndGuestCountLimits) =>
        guestCount === item.guestCount,
    ).map(({ time }) => time),
  );

const getSelectedTime = (
  availabilities: AdminAvailabilityData[],
  selectedGuestCount: number,
): string => {
  const availableTimes = getAvailableTimesForGuestCount(
    availabilities,
    selectedGuestCount,
  );
  return availableTimes[0] || DEFAULT_SELECTED_TIME;
};

const filterAvailabilitiesByGuestCount = (
  availabilities: AdminAvailabilityData[],
  guestCount: number,
): AdminAvailabilityData[] =>
  filter(
    availabilities,
    (item: AdminAvailabilityData) => guestCount === item.guestCount,
  );

const filterAvailabilitiesByTime = (
  availabilities: AdminAvailabilityData[],
  time: string,
): AdminAvailabilityData[] => {
  const selectedTimeDate = createReferenceDate(time);
  const timesToFilter = eachMinuteOfInterval(
    {
      start: selectedTimeDate,
      end: addHours(selectedTimeDate, DEFAULT_TIME_WINDOW_IN_HOURS),
    },
    { step: 15 },
  ).map(dateToISOTime);

  return filter(availabilities, (item: AdminAvailabilityData) =>
    timesToFilter.includes(item.time),
  );
};

export interface UseAdminAvailabilityReturn {
  availabilities: AdminAvailabilityData[];
  availableTimes: string[];
  refetchAvailabilities: () => void;
  handleShowEarlier: () => void;
  handleShowLater: () => void;
  hasEarlier: boolean;
  hasLater: boolean;
  isClosedToday: boolean;
  selectedAvailability?: AdminAvailabilityData;
  selectedDate: Date;
  selectedGuestCount: number;
  selectedTime: string;
  setSelectedAvailability: (availability?: AdminAvailabilityData) => void;
  setSelectedDate: (date: Date) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
}

export const useAdminAvailability = (): UseAdminAvailabilityReturn => {
  const restaurant = useRestaurant();
  const [availability, setAvailability] = useState<AdminAvailabilityData[]>([]);
  const [isClosedToday, setIsClosedToday] = useState(true);
  const [selectedAvailability, setSelectedAvailability] =
    useState<AdminAvailabilityData>();
  const [selectedDate, setSelectedDate] = useState<Date>(
    TZDateMini.tz(restaurant.timezone),
  );
  const [selectedGuestCount, setSelectedGuestCount] =
    useState<number>(DEFAULT_GUEST_COUNT);
  const [selectedTime, setSelectedTime] = useState<string>(
    DEFAULT_SELECTED_TIME,
  );
  const [showEarlierOffset, setShowEarlierOffset] = useState<number>(
    DEFAULT_SHOW_MORE_OFFSET,
  );
  const [showLaterOffset, setShowLaterOffset] = useState<number>(
    DEFAULT_SHOW_MORE_OFFSET,
  );

  const setError = useError();

  const fetchAvailability = useCallback(async () => {
    const availabilities = await getAvailabilities(restaurant.id, selectedDate);
    setAvailability(availabilities);
    setSelectedTime(getSelectedTime(availabilities, selectedGuestCount));
  }, [restaurant.id, selectedDate]);

  useEffect(() => {
    void fetchAvailability();
  }, [selectedDate]);

  useEffect(() => {
    setSelectedTime(getSelectedTime(availability, selectedGuestCount));
  }, [selectedGuestCount]);

  useEffect(() => {
    const checkRestaurantIsClosed = async () => {
      try {
        const isRestaurantClosedResponse = await isRestaurantClosed(
          restaurant.id,
          todayInTimezone(restaurant.timezone),
        );

        setIsClosedToday(isRestaurantClosedResponse);
      } catch (e) {
        setError(e);
      }
    };

    void checkRestaurantIsClosed();
  }, [restaurant.id]);

  useEffect(() => {
    setShowEarlierOffset(DEFAULT_SHOW_MORE_OFFSET);
  }, [selectedGuestCount, selectedTime]);

  const availabilitiesFilteredByGuestCount = filterAvailabilitiesByGuestCount(
    availability,
    selectedGuestCount,
  );

  const availabilitiesFilteredByGuestCountAndTime = filterAvailabilitiesByTime(
    availabilitiesFilteredByGuestCount,
    selectedTime,
  );

  // start index is inclusive
  const indexOfFirstFilteredAvailability = findIndex(
    availabilitiesFilteredByGuestCount,
    availabilitiesFilteredByGuestCountAndTime[0],
  );
  // end index is exclusive
  const indexOfLastFilteredAvailability =
    indexOfFirstFilteredAvailability +
    availabilitiesFilteredByGuestCountAndTime.length;

  const indexOfFirstExpandedAvailability =
    indexOfFirstFilteredAvailability - showEarlierOffset <= MIN_START_INDEX
      ? MIN_START_INDEX
      : indexOfFirstFilteredAvailability - showEarlierOffset;
  const indexOfLastExpandedAvailability =
    indexOfLastFilteredAvailability + showLaterOffset;

  const expandedAvailability = availabilitiesFilteredByGuestCount.slice(
    indexOfFirstExpandedAvailability,
    indexOfLastExpandedAvailability,
  );

  const availableTimes = getAvailableTimesForGuestCount(
    availability,
    selectedGuestCount,
  );

  const handleShowEarlier = (): void => {
    setShowEarlierOffset(showEarlierOffset + SHOW_MORE_OFFSET);
  };
  const handleShowLater = (): void => {
    setShowLaterOffset(showLaterOffset + SHOW_MORE_OFFSET);
  };

  const hasEarlier = indexOfFirstExpandedAvailability > MIN_START_INDEX;
  const hasLater =
    indexOfLastExpandedAvailability < availabilitiesFilteredByGuestCount.length;

  return {
    availabilities: expandedAvailability,
    availableTimes,
    refetchAvailabilities: fetchAvailability,
    handleShowEarlier,
    handleShowLater,
    hasEarlier,
    hasLater,
    isClosedToday,
    selectedAvailability,
    selectedDate,
    selectedGuestCount,
    selectedTime,
    setSelectedAvailability,
    setSelectedDate,
    setSelectedGuestCount,
    setSelectedTime,
  };
};

import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { IPAD1024 } from '@shared/styles/breakpoints';
import { updateSchedule } from '../apiHelpers';
import { SCHEDULE_SETTINGS_EXPLANATION } from './constants';
import styles from './ScheduleForm.scss';

const SCHEDULE_OPTIONS = Array.from({ length: 30 }, (_, i) => ({
  label: `${i + 1} day${i === 0 ? '' : 's'} in advance`,
  value: i + 1,
}));

interface ScheduleFormData {
  schedule: number;
}

export interface ScheduleFormProps {
  schedule: number;
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const ScheduleForm = ({
  schedule,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: ScheduleFormProps) => {
  const { control, handleSubmit } = useForm<ScheduleFormData>({
    defaultValues: {
      schedule,
    },
  });

  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      await updateSchedule(restaurantId, data.schedule);

      await refreshRestaurant();
      successToast({ message: 'Schedule successfully updated' });
      exitEditMode();
    } catch (error) {
      errorToast({ message: 'Failed to update Schedule' });
      reportAppError(error);
    }
  });

  const layoutVariant = useMediaQuery({ minWidth: IPAD1024 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <form onSubmit={handleOnSubmit} className={styles.form}>
      <ControlledFormSelect
        control={control}
        label="Publish to Market"
        name="schedule"
        options={SCHEDULE_OPTIONS}
        rules={{ required: true }}
        tooltipText={SCHEDULE_SETTINGS_EXPLANATION}
        variant={layoutVariant}
      />
      <div className={styles.actions}>
        <Button
          variant={ButtonVariants.Tertiary}
          label="Discard Edits"
          onClick={exitEditMode}
        />
        <Button
          label="Save Changes"
          type="submit"
          variant={ButtonVariants.Primary}
        />
      </div>
    </form>
  );
};

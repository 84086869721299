import { useLocation } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { LinkStyledAsButton } from '@components/linkStyledAsButton/LinkStyledAsButton';
import { PageContent } from '../../../layout/PageContent';
import { PageHeader } from '../../../layout/PageHeader';
import { ListingForm } from '../form/ListingForm';
import { CreateListingErrorModal } from './CreateListingErrorModal';
import { CreateListingWarningModal } from './CreateListingWarningModal';
import { useCreateListing } from './useCreateListing';

export const CreateListingPage = () => {
  const { state } = useLocation();
  const {
    closeErrorModal,
    closeWarningModal,
    control,
    errorModalData,
    floorPlan,
    handleOnClickPublish,
    handleOnClickSaveDraft,
    handleOnConfirmSubmit,
    isErrorModalOpen,
    isWarningModalOpen,
    referrer,
    warningModalData,
    setValue,
    resetField,
  } = useCreateListing();

  if (!floorPlan) return null;

  return (
    <>
      <PageHeader category="Operations" title="Create Listing">
        <LinkStyledAsButton
          label="Cancel"
          to={referrer}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Save Draft"
          variant={ButtonVariants.Secondary}
          type="button"
          onClick={handleOnClickSaveDraft}
        />
        <Button
          label="Publish"
          variant={ButtonVariants.Primary}
          type="button"
          onClick={handleOnClickPublish}
        />
      </PageHeader>
      <PageContent>
        <ListingForm
          control={control}
          setValue={setValue}
          resetField={resetField}
          floorPlan={floorPlan}
          isNewListing={!state.duplicateListingId}
        />
      </PageContent>
      <CreateListingErrorModal
        isOpen={isErrorModalOpen}
        closeModal={closeErrorModal}
        message={errorModalData?.message}
      />
      <CreateListingWarningModal
        isOpen={isWarningModalOpen}
        closeModal={closeWarningModal}
        message={warningModalData?.message}
        isDraft={warningModalData?.isDraft}
        handleOnConfirmSubmit={handleOnConfirmSubmit}
      />
    </>
  );
};

import { max } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { reportAppError } from '@shared/reportAppError';
import { CENTS_IN_DOLLAR } from '@utils/currency';
import { todayInTimezone } from '@utils/dateFormatters';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  createListing,
  getListing,
  type ListingWarnings,
  type NewListing,
} from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type { ListingFormData } from '../types';
import {
  getDefaultFormValues,
  getDestinationPath,
} from '../utils/listingUtils';

export const useCreateListing = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id: restaurantId, timezone } = useRestaurant();
  const {
    floorPlan,
    refreshFloorPlan,
    refreshListings,
    setSelectedDate,
    setSelectedListingId,
  } = useListingsContext();

  const { data: duplicateListing } = useAbortEffect(
    async (signal) => {
      if (state.duplicateListingId) {
        return getListing(state.duplicateListingId, restaurantId, signal);
      }
      return undefined;
    },
    [restaurantId, state.duplicateListingId],
  );

  const { control, handleSubmit, setValue, resetField } =
    useForm<ListingFormData>({
      values: getDefaultFormValues(
        floorPlan,
        duplicateListing,
        !!duplicateListing,
      ),
    });

  const {
    isOpen: isErrorModalOpen,
    data: errorModalData,
    open: openErrorModal,
    close: closeErrorModal,
  } = useIsOpenWithData<{ message: string }>();
  const {
    isOpen: isWarningModalOpen,
    data: warningModalData,
    open: openWarningModal,
    close: closeWarningModal,
  } = useIsOpenWithData<{ message: string; isDraft: boolean }>();

  const buildPayload = ({
    data,
    ignoreWarnings,
  }: {
    data: ListingFormData;
    ignoreWarnings: boolean;
  }) => ({
    endDate: data.endDate || null,
    endTime: data.endTime,
    highlightedFloorPlanTableIds: data.highlightedTables.map(
      (table) => table.id,
    ),
    iconName: data.iconName,
    interval: data.interval,
    inventoryCount: Number(data.inventoryCount),
    isCommunal: data.isCommunal,
    maximumGuests: Number(data.maximumGuests),
    minimumGuests: Number(data.minimumGuests),
    name: data.name,
    price: Number(data.price) * CENTS_IN_DOLLAR,
    pricePoints: data.pricePoints.map((pricePoint) => ({
      activeDays: pricePoint.activeDays,
      endTime: pricePoint.endTime,
      price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
      startTime: pricePoint.startTime,
    })),
    publicName: data.publicName,
    repeat: data.repeat,
    startDate: data.startDate,
    startTime: data.startTime,
    turnTime: data.turnTime,
    ignoreWarnings,
  });

  const handleOnClickPublish = handleSubmit(async (data: ListingFormData) => {
    let response: ListingWarnings | NewListing;

    try {
      response = await createListing(
        restaurantId,
        buildPayload({ data, ignoreWarnings: false }),
        false,
      );

      if ('warnings' in response) {
        openWarningModal({ message: response.warnings[0], isDraft: false });
      } else {
        successToast({
          message: 'Listing successfully published',
        });
        refreshListings();
        refreshFloorPlan();
        setSelectedDate(max([data.startDate, todayInTimezone(timezone)])!);
        setSelectedListingId(response.createdListingId);
        navigate(getDestinationPath(state.referrer, 'published'));
      }
    } catch (e) {
      if (e instanceof ApiError) {
        openErrorModal({ message: e.message });
      }
      reportAppError(e);
    }
  });

  const handleOnClickSaveDraft = handleSubmit(async (data: ListingFormData) => {
    let response: ListingWarnings | NewListing;

    try {
      response = await createListing(
        restaurantId,
        buildPayload({ data, ignoreWarnings: false }),
        true,
      );

      if ('warnings' in response) {
        openWarningModal({ message: response.warnings[0], isDraft: true });
      } else {
        successToast({
          message: 'Listing successfully saved as draft',
        });
        refreshListings();
        refreshFloorPlan();
        setSelectedDate(max([data.startDate, todayInTimezone(timezone)])!);
        setSelectedListingId(response.createdListingId);
        navigate(getDestinationPath(state.referrer, 'draft'));
      }
    } catch (e) {
      if (e instanceof ApiError) {
        openErrorModal({ message: e.message });
      }
      reportAppError(e);
    }
  });

  const handleOnConfirmSubmit = handleSubmit(async (data: ListingFormData) => {
    try {
      const response = await createListing(
        restaurantId,
        buildPayload({ data, ignoreWarnings: true }),
        warningModalData?.isDraft,
      );

      successToast({
        message: `Listing successfully ${warningModalData?.isDraft ? 'saved as draft' : 'published'}`,
      });
      refreshListings();
      refreshFloorPlan();
      setSelectedDate(max([data.startDate, todayInTimezone(timezone)])!);
      setSelectedListingId((response as NewListing).createdListingId);
      navigate(
        getDestinationPath(
          state.referrer,
          warningModalData?.isDraft ? 'draft' : 'published',
        ),
      );
    } catch (e) {
      if (e instanceof ApiError) {
        openErrorModal({ message: e.message });
      }
      reportAppError(e);
    }
  });

  return {
    closeErrorModal,
    closeWarningModal,
    control,
    errorModalData,
    floorPlan,
    handleOnClickPublish,
    handleOnClickSaveDraft,
    handleOnConfirmSubmit,
    isErrorModalOpen,
    isWarningModalOpen,
    referrer: state.referrer,
    warningModalData,
    setValue,
    resetField,
  };
};

import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast } from '@components/toasts/Toasts';
import { ApiServerError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, deactivateAdmin } from './apiHelpers';

export interface DeactivateAdminConfirmationModalProps {
  admin: Admin;
  closeModal: () => void;
  refetchRestaurantAdmins: () => void;
  isOpen: boolean;
}

export const DeactivateAdminConfirmationModal = ({
  admin,
  closeModal,
  refetchRestaurantAdmins,
  isOpen,
}: DeactivateAdminConfirmationModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleDeactivate = async () => {
    setIsLoading(true);

    try {
      await deactivateAdmin(restaurantId, admin.id);
      refetchRestaurantAdmins();
    } catch (e) {
      if (e instanceof ApiServerError) errorToast({ message: e.message });
      reportAppError(e);
    } finally {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      subtitle={`Are you sure you want to deactivate ${admin.fullName}'s account?`}
      title="Deactivate Admin"
    >
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isLoading}
          label="Confirm"
          onClick={handleDeactivate}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};

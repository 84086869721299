import { offset } from '@floating-ui/react';
import { MenuButton, Menu as MuiMenu } from '@mui/base';
import { Dropdown } from '@mui/base/Dropdown';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem, MenuItemVariant } from '@components/menu/MenuItem';
import { MenuListbox } from '@components/menu/MenuListbox';
import { MenuSeparator } from '@components/menu/MenuSeparator';
import {
  OPERATIONS_LISTINGS_CREATE_PATH,
  OPERATIONS_LISTINGS_EDIT_PATH,
} from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import type { Listing } from '../apiHelpers';
import { DeleteListingConfirmationModal } from './DeleteListingConfirmationModal';
import styles from './ListingMenu.scss';
import { PublishListingConfirmationModal } from './PublishListingConfirmationModal';
import { UnpublishListingConfirmationModal } from './UnpublishListingConfirmationModal';

interface ListingMenuProps {
  listing: Listing;
}

export const ListingMenu = ({ listing }: ListingMenuProps) => {
  const { pathname } = useLocation();
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] =
    useState(false);
  const [isConfirmUnpublishModalOpen, setIsConfirmUnpublishModalOpen] =
    useState(false);

  const navigate = useNavigate();

  const menuRef = useRef<HTMLElement>(null);
  const refCurrent = () => menuRef.current!;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={(e): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseDown={(e): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Dropdown>
        <MenuButton className={cx(typography.h8, styles.options)} ref={menuRef}>
          Options
        </MenuButton>
        <MuiMenu
          anchor={refCurrent}
          slots={{ listbox: MenuListbox }}
          slotProps={{
            root: { middleware: [offset(8)], placement: 'left-start' },
          }}
        >
          {listing.status === 'draft' && (
            <MenuItem onClick={() => setIsConfirmPublishModalOpen(true)}>
              Publish Listing
            </MenuItem>
          )}
          {listing.status === 'active' && (
            <MenuItem onClick={() => setIsConfirmUnpublishModalOpen(true)}>
              Unpublish Listing
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              navigate(OPERATIONS_LISTINGS_CREATE_PATH, {
                state: {
                  referrer: pathname,
                  duplicateListingId: listing.id,
                },
              })
            }
          >
            Duplicate Listing
          </MenuItem>
          <MenuItem
            onClick={() =>
              navigate(`${OPERATIONS_LISTINGS_EDIT_PATH}/${listing.id}`, {
                state: {
                  referrer: pathname,
                },
              })
            }
          >
            Edit Listing
          </MenuItem>
          <MenuSeparator />
          <MenuItem
            onClick={() => setIsConfirmDeleteModalOpen(true)}
            variant={MenuItemVariant.Pink}
          >
            Delete Listing
          </MenuItem>
        </MuiMenu>
      </Dropdown>
      <DeleteListingConfirmationModal
        closeModal={() => setIsConfirmDeleteModalOpen(false)}
        isOpen={isConfirmDeleteModalOpen}
        listing={listing}
      />
      <PublishListingConfirmationModal
        closeModal={() => setIsConfirmPublishModalOpen(false)}
        isOpen={isConfirmPublishModalOpen}
        listing={listing}
      />
      <UnpublishListingConfirmationModal
        closeModal={() => setIsConfirmUnpublishModalOpen(false)}
        isOpen={isConfirmUnpublishModalOpen}
        listing={listing}
      />
    </div>
  );
};

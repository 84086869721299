import { useDraggable } from '@dnd-kit/core';
import cx from 'classnames';
import { add, isAfter } from 'date-fns';
import { type MouseEventHandler } from 'react';
import { Icon } from '@components/icon/Icon';
import { toUTCDateTime } from '@shared/utils/dateParsers';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { getFullName } from '@utils/formatName';
import { ISOTimeTo12HourTime } from '@utils/time';
import {
  Status,
  StatusTag,
} from 'restaurantAdmin/components/statusTag/StatusTag';
import { usePublishedListings } from 'restaurantAdmin/context/PublishedListingsContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { Listing } from 'restaurantAdmin/operations/listings/apiHelpers';
import { type RestaurantMetadata } from 'restaurantAdmin/restaurants/apiHelpers';
import typography from '~styles/typography.scss';
import type { ServiceReservation } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import styles from './ActiveReservationCard.scss';
import { ServerBadge } from './servers/ServerBadge';

interface ReservationCardProps {
  isSelected?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onUpdated: () => void;
  reservation: ServiceReservation;
}

export const ActiveReservationCard = ({
  isSelected = false,
  onClick,
  onUpdated,
  reservation,
}: ReservationCardProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    data: {
      reservation,
      type: 'reservation',
    },
    id: reservation.id,
  });

  const restaurant = useRestaurant();
  const { listings } = usePublishedListings();

  const {
    guest,
    guestCount,
    hasDietaryRestrictionOrAllergy,
    hasNote,
    isStranded,
    isVIP,
    listingName,
    seatedTableName,
    serviceStatus,
    time,
    server,
  } = reservation;

  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isSeated = seatedTableName;

  const isOverTime =
    isSeated && calculateIsOverTime(reservation, restaurant, listings);

  const showCardFooter = isStranded || isOverTime;
  const guestName = getFullName(guest.firstName, guest.lastName);

  return (
    <li
      aria-current={isSelected}
      aria-label={`reservation card for ${guest.firstName} ${guest.lastName}`}
      className={styles.card}
    >
      <ServiceStatusSelect
        occupantType="reservation"
        occupantId={reservation.id}
        onUpdated={onUpdated}
        value={serviceStatus}
      />
      <button
        aria-label="open details"
        className={styles.button}
        onClick={onClick}
        type="button"
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <div className={styles.infoContainer}>
          <div
            data-testid={`reservation-status-row-${reservation.id}`}
            className={styles.timeAndStatusRow}
          >
            <div className={cx(typography.h8, styles.time)}>
              {ISOTimeTo12HourTime(time)}
              {isVIP && (
                <Icon
                  ariaLabel="Is a VIP"
                  className={cx(styles.vipIcon)}
                  name="crown"
                  role="img"
                  fill="var(--gold)"
                />
              )}
              {isHostBooked && (
                <Icon
                  ariaLabel="Host booked"
                  className={styles.fillIcon}
                  name="roomService"
                  role="img"
                />
              )}
              {hasNote && (
                <Icon
                  ariaLabel="Has notes"
                  className={cx(styles.strokeIcon, styles.hasNoteIcon)}
                  name="note"
                  role="img"
                />
              )}
              {hasDietaryRestrictionOrAllergy && (
                <Icon
                  ariaLabel="Has dietary restriction or allergy"
                  name="cutlery"
                  role="img"
                />
              )}
            </div>
            {isSeated && (
              <div className={styles.status}>
                <div className={typography.t3}>Seated</div>
                {server && (
                  <ServerBadge
                    displayMode="card"
                    badgeColor={server.hexColor}
                    serverName={server.name}
                  />
                )}
              </div>
            )}
          </div>
          <div className={styles.nameAndGuestCountRow}>
            <div className={styles.userFullName}>{guestName}</div>
            <div className={styles.guestStack}>
              <div>
                <span className={typography.t3}>{listingName}</span>
                {seatedTableName && (
                  <span className={cx(typography.t3, styles.arrow)}>
                    {seatedTableName}
                  </span>
                )}
              </div>
              <div
                aria-label={appendGuestCountLabel(guestCount)}
                className={styles.guestCount}
              >
                <Icon name="couple" /> {guestCount}
              </div>
            </div>
          </div>
        </div>
        {showCardFooter && (
          <div className={styles.cardFooter}>
            {isOverTime && <StatusTag status={Status.Overtime} />}
            {isStranded && <StatusTag status={Status.Stranded} />}
          </div>
        )}
      </button>
    </li>
  );
};

const calculateIsOverTime = (
  reservation: ServiceReservation,
  restaurant: RestaurantMetadata,
  listings: Listing[],
) => {
  const { timezone } = restaurant;

  const { turnTime } =
    listings.find((listing) => reservation.listingId === listing.id) ||
    ({} as Listing);
  if (!turnTime) {
    return false;
  }

  const reservationDateTimeUTC = toUTCDateTime(
    reservation.date,
    reservation.time,
    timezone,
  );

  const endOfTurn = add(reservationDateTimeUTC, {
    minutes: turnTime,
  });

  return isAfter(new Date(), endOfTurn);
};

import cx from 'classnames';
import type {
  Control,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import { Card } from '@components/card/Card';
import { type FloorPlanData } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import type { ListingFormData } from '../types';
import { AdvancedOptionsFieldSet } from './AdvancedOptionsFieldSet';
import { BasePriceFieldSet } from './BasePriceFieldSet';
import { FloorPlanFieldset } from './FloorPlanFieldset';
import styles from './ListingForm.scss';
import { ListingLifeSpanFieldSet } from './ListingLifeSpanFieldSet';
import { PricePointFieldSet } from './PricePointFieldSet';
import { PublicNameFieldSet } from './PublicNameFieldSet';
import { ReservableDaysFieldSet } from './ReservableDaysFieldSet';
import { ReservableTimesFieldSet } from './ReservableTimesFieldSet';

interface ListingFormProps {
  control: Control<ListingFormData>;
  setValue: UseFormSetValue<ListingFormData>;
  resetField: UseFormResetField<ListingFormData>;
  floorPlan: FloorPlanData;
  isNewListing: boolean;
}

export const ListingForm = ({
  control,
  floorPlan,
  isNewListing,
  setValue,
  resetField,
}: ListingFormProps) => (
  <form className={styles.form}>
    <Card className={cx(styles.card, styles.floorPlan)}>
      <FloorPlanFieldset control={control} floorPlan={floorPlan} />
    </Card>
    <Card className={styles.card}>
      <h3 className={typography.h7}>Listing Details</h3>
      <PublicNameFieldSet control={control} />
      <ListingLifeSpanFieldSet control={control} />
      <ReservableDaysFieldSet control={control} />
      <ReservableTimesFieldSet control={control} />
      <AdvancedOptionsFieldSet
        control={control}
        isNewListing={isNewListing}
        setValue={setValue}
        resetField={resetField}
      />
    </Card>
    <Card className={styles.card}>
      <h3 className={typography.h7}>Pricing</h3>
      <BasePriceFieldSet control={control} />
      <PricePointFieldSet control={control} />
    </Card>
  </form>
);

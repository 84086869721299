import type {
  FloorPlanData,
  IconNameType,
  ListingId,
} from '@shared/types/floorPlans';
import { api } from 'restaurantAdmin/api';
import { type AvailableWalkInTime } from 'restaurantAdmin/reservations/service/apiHelpers';
import { type ServiceStatus } from 'restaurantAdmin/reservations/service/serviceStatus/ServiceStatus';

export interface SeatedOccupant {
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  serviceStatus: ServiceStatus;
  type: 'reservation' | 'walkIn';
}

export interface AssignedServer {
  id: string;
  name: string;
  hexColor: string;
}

export interface HostFloorPlanTable {
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  left: string;
  maximumGuests: number;
  mergeTableId: string | null;
  mergeTableName: string | null;
  minimumGuests: number;
  name: string;
  orientation: string;
  seatedOccupant: SeatedOccupant | null;
  listings: ListingId[];
  top: string;
  turnTime: number;
  assignedServer: AssignedServer | null;
}

export interface HostFloorPlanData {
  id: string;
  backgroundSrc?: string;
  floorPlanTables: HostFloorPlanTable[];
}

export const getFloorPlanForRestaurantId = (
  restaurantId: string,
  activeListingsOnly: boolean,
): Promise<FloorPlanData> =>
  api.get(
    `/restaurants/${restaurantId}/floor-plan${activeListingsOnly ? '?activeListingsOnly=true' : '?activeListingsOnly=false'}`,
  );

export const getHostFloorPlanForRestaurantId = (
  restaurantId: string,
): Promise<HostFloorPlanData> =>
  api.get(`/restaurants/${restaurantId}/admin-floor-plans/seating`);

export interface HostFloorPlanTableAvailableTime {
  floorPlanTableId: string;
  availableTime: AvailableWalkInTime;
}

export const getAvailableTimesForRestaurantId = async (
  restaurantId: string,
): Promise<HostFloorPlanTableAvailableTime[]> =>
  api.get(`/restaurants/${restaurantId}/admin-floor-plans/available-times`);

export const unassignAllServers = (restaurantId: string): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/admin-floor-plans/unassign-all-servers`,
  );

import type { IconNameType } from '@shared/types/floorPlans';
import { api } from '../../api';

export interface CreatePricePointPayload {
  activeDays: string[];
  endTime: string;
  price: number;
  startTime: string;
}

export interface EditPricePointPayload {
  activeDays: string[];
  endTime: string;
  id: string;
  price: number;
  startTime: string;
}

export interface DeletePricePointPayload {
  id: string;
}

export interface EditPricePointsPayload {
  added: CreatePricePointPayload[];
  edited: EditPricePointPayload[];
  removed: DeletePricePointPayload[];
}

export interface CreateListingPayload {
  endDate: string | null;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings?: boolean;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  repeat: string[];
  startDate: string;
  startTime: string;
  pricePoints?: CreatePricePointPayload[];
  publicName: string;
  turnTime: number;
}

export interface EditListingPayload {
  endDate: string | null;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings: boolean;
  interval: number;
  inventoryCount: number;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  pricePoints?: EditPricePointsPayload;
  repeat: string[];
  startDate: string;
  startTime: string;
  publicName: string;
}

export interface ListingWarnings {
  warnings: string[];
}

export interface NewListing {
  createdListingId: string;
}

export interface PricePoint {
  activeDays: string[];
  endTime: string;
  id: string;
  price: number;
  startTime: string;
}

export interface Listing {
  endDate: string | null;
  endTime: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  id: string;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  publicName: string;
  repeat: string[];
  restaurantId: string;
  startDate: string;
  startTime: string;
  status: 'active' | 'draft';
  turnTime: number;
  pricePoints: PricePoint[];
}

interface ListingWithServiceWindows {
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  id: string;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  publicName: string;
  restaurantId: string;
  status: 'active' | 'draft';
  turnTime: number;
  pricePoints: PricePoint[];
  serviceWindows: {
    id: string;
    name: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    startTime: string;
    endTime: string;
  }[];
}

interface ListingHasConflicts {
  hasConflicts: boolean;
}

export const createListing = (
  restaurantId: string,
  listing: CreateListingPayload,
  isDraft?: boolean,
): Promise<ListingWarnings | NewListing> =>
  api.post(
    `/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`,
    listing,
  );

export const deleteListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/listings/${listingId}`);

export const editListing = (
  restaurantId: string,
  listingId: string,
  listing: EditListingPayload,
): Promise<ListingWarnings> =>
  api.put(`/restaurants/${restaurantId}/listings/${listingId}`, listing);

export const getListing = async (
  listingId: string,
  restaurantId: string,
  signal?: AbortSignal,
): Promise<Listing> => {
  const listing = await api.get<ListingWithServiceWindows>(
    `/restaurants/${restaurantId}/listings/${listingId}`,
    { signal },
  );

  const { serviceWindows, ...listingAttrs } = listing;
  const { startDate, endDate, repeat, startTime, endTime } = serviceWindows[0];

  return {
    ...listingAttrs,
    startDate,
    endDate,
    repeat,
    startTime,
    endTime,
  };
};

export const getListings = async (
  restaurantId: string,
  isDraft?: boolean,
  signal?: AbortSignal,
): Promise<Listing[]> => {
  const listings = await api.get<ListingWithServiceWindows[]>(
    `/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`,
    {
      signal,
    },
  );

  return listings.map((listing) => {
    const { serviceWindows, ...listingAttrs } = listing;
    const { startDate, endDate, repeat, startTime, endTime } =
      serviceWindows[0];

    return {
      ...listingAttrs,
      startDate,
      endDate,
      repeat,
      startTime,
      endTime,
    };
  }) as Listing[];
};

export const unpublishListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/listings/${listingId}/unpublish`);

export const publishListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/listings/${listingId}/publish`);

export const checkListingHasConflicts = (
  restaurantId: string,
  listingId: string,
  signal?: AbortSignal,
): Promise<ListingHasConflicts> =>
  api.get(`/restaurants/${restaurantId}/listings/${listingId}/has-conflicts`, {
    signal,
  });

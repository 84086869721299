import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  noShow,
  unseatReservation,
} from 'restaurantAdmin/reservations/apiHelpers';
import { isReservation } from 'restaurantAdmin/reservations/service/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import { GuestFinishedConfirmationModal } from './GuestFinishedConfirmationModal';
import { NoShowConfirmationModal } from './NoShowConfirmationModal';
import { UnseatGuestConfirmationModal } from './UnseatGuestConfirmationModal';

export interface ReservationGuestSheetActionsProps {
  isSeated: boolean;
  isStranded: boolean;
  onEnableSeatMode: () => void;
  reservationId: string;
  seatModeEnabled: boolean;
}

const SUCCESS_MESSAGE =
  'Success: The reservation has been flagged as a no show';

export const ReservationGuestSheetActions = ({
  isSeated,
  isStranded,
  onEnableSeatMode,
  reservationId,
  seatModeEnabled,
}: ReservationGuestSheetActionsProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const {
    isOpen: isUnseatModalOpen,
    open: openUnseatModal,
    close: closeUnseatModal,
  } = useIsOpen();
  const { id: restaurantId } = useRestaurant();
  const {
    closeSidePanelSheet,
    refreshOccupants,
    refreshFloorPlan,
    selectedOccupant,
  } = useReservationServiceContext();

  const handleOnClickNoShow = async () => {
    try {
      await noShow({
        restaurantId,
        reservationId,
      });

      refreshOccupants();
      successToast({ message: SUCCESS_MESSAGE });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to flag reservation as no show. Please try again.',
        });
      }
      reportAppError(error);
    }
    closeSidePanelSheet();
  };

  const onFinishReservation = () => {
    refreshFloorPlan();
    refreshOccupants();
    closeSidePanelSheet();
  };

  const handleUnseatGuest = async (): Promise<void> => {
    if (!isReservation(selectedOccupant)) return;

    try {
      await unseatReservation({
        restaurantId,
        reservationId: selectedOccupant.id,
      });

      closeSidePanelSheet();
      refreshOccupants();
      refreshFloorPlan();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to unseat guest. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  if (seatModeEnabled) {
    return null;
  }

  const showNoShowButton = !isSeated;
  const showSeatOrChangeSeatButton = !seatModeEnabled;
  const showFinishAndUnseatButtons = isSeated;
  const hasReservationActions =
    showNoShowButton ||
    showSeatOrChangeSeatButton ||
    showFinishAndUnseatButtons;

  return hasReservationActions ? (
    <>
      {showSeatOrChangeSeatButton && !isSeated && (
        <Button
          isDisabled={isStranded}
          label="Seat Guest"
          onClick={onEnableSeatMode}
          variant={ButtonVariants.Primary}
        />
      )}
      {showNoShowButton && (
        <>
          <Button
            variant={ButtonVariants.Tertiary}
            onClick={openModal}
            label="No Show"
          />
          <NoShowConfirmationModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            handleOnClickNoShow={() => handleOnClickNoShow()}
          />
        </>
      )}
      {showFinishAndUnseatButtons && (
        <>
          <Button
            label="Guest Finished"
            onClick={openModal}
            variant={ButtonVariants.Primary}
          />
          <GuestFinishedConfirmationModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            onFinishReservation={onFinishReservation}
            reservationId={reservationId}
          />
        </>
      )}
      {showSeatOrChangeSeatButton && isSeated && (
        <Button
          isDisabled={isStranded}
          label="Change Seat"
          onClick={onEnableSeatMode}
          variant={ButtonVariants.Tertiary}
        />
      )}
      {showFinishAndUnseatButtons && (
        <>
          <Button
            label="Unseat Guest"
            onClick={openUnseatModal}
            variant={ButtonVariants.Tertiary}
          />
          <UnseatGuestConfirmationModal
            closeModal={closeUnseatModal}
            isOpen={isUnseatModalOpen}
            onUnseatGuest={handleUnseatGuest}
          />
        </>
      )}
    </>
  ) : null;
};

import { type Control, useWatch } from 'react-hook-form';
import { ControlledFormTimeSelect } from '@components/formInputs/ControlledFormTimeSelect';
import typography from '~styles/typography.scss';
import { type ListingFormData } from '../types';
import styles from './ReservableTimesFieldSet.scss';

export interface ReservableTimesFieldSetProps {
  control: Control<ListingFormData>;
}

export const ReservableTimesFieldSet = ({
  control,
}: ReservableTimesFieldSetProps) => {
  const startTime = useWatch({
    control,
    name: 'startTime',
  });

  const isGreaterThanOrEqualToStartTime = (
    endTime: string,
  ): string | boolean =>
    startTime && endTime >= startTime
      ? true
      : 'End Time must not be earlier than Start Time';

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="reservable-times-fieldset"
    >
      <h3 className={typography.t3}>reservable times</h3>
      <ControlledFormTimeSelect
        control={control}
        label="Start Time"
        name="startTime"
        rules={{
          required: true,
        }}
      />
      <ControlledFormTimeSelect
        control={control}
        label="End Time"
        name="endTime"
        rules={{
          required: true,
          validate: {
            isGreaterThanOrEqualToStartTime,
          },
        }}
      />
    </fieldset>
  );
};

import { type Control } from 'react-hook-form';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import typography from '~styles/typography.scss';
import { type ListingFormData } from '../types';
import styles from './ReservableDaysFieldSet.scss';

export interface ReservableDaysFieldSetProps {
  control: Control<ListingFormData>;
}

export const ReservableDaysFieldSet = ({
  control,
}: ReservableDaysFieldSetProps) => (
  <fieldset className={styles.fieldset} data-testid="reservable-days-fieldset">
    <h3 className={typography.t3}>reservable days</h3>
    <ControlledFormDayRadio
      control={control}
      label="Repeats On"
      name="repeat"
      rules={{
        required: true,
      }}
    />
  </fieldset>
);

import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlan } from '../../hooks/useAdminFloorPlan';
import {
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH,
  OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../paths';
import type { Listing } from './apiHelpers';
import { useListings } from './useListings';
import { getListingsForWeekByDate } from './utils/listingUtils';

export interface ListingsContextState {
  clearSelectedListing: () => void;
  floorPlan: FloorPlanData;
  isLoading: boolean;
  /** All listings in the selected scope (Published, Draft, Inactive) */
  scopedListings: Listing[];
  /** Listings in scope for the entire page - filtered by date/time selectors */
  pageListings: Listing[];
  /** Listings in scope for the list view - filtered by selection on the calendar or the floor plan */
  listListings: Listing[];
  refreshFloorPlan: () => void;
  refreshListings: () => void;
  selectedDate: string;
  selectedFloorPlanTableListingIds: string[];
  selectedListing: Listing | undefined;
  setSelectedDate: (date: string) => void;
  setSelectedFloorPlanTableListingIds: Dispatch<SetStateAction<string[]>>;
  setSelectedListingId: (listingId: string) => void;
  selectedCalendarCellListingIds: string[];
  setSelectedCalendarCellListingIds: Dispatch<SetStateAction<string[]>>;
}

export const ListingsContext = createContext<ListingsContextState | null>(null);
ListingsContext.displayName = 'ListingsContext';

export const useListingsContext = (): ListingsContextState =>
  useDefinedContext(ListingsContext);

export const ListingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // library hooks
  const { pathname } = useLocation();

  // internal hooks
  const { timezone } = useRestaurant();
  const {
    isLoading: isListingsLoading,
    listings: fetchedListings,
    refreshListings,
  } = useListings(pathname.includes('draft'));
  const {
    isLoading: isFloorPlanLoading,
    floorPlan,
    fetchFloorPlan,
  } = useAdminFloorPlan(false);

  // local state
  const [selectedDate, setSelectedDate] = useState(() =>
    todayInTimezone(timezone),
  );
  const [
    selectedFloorPlanTableListingIds,
    setSelectedFloorPlanTableListingIds,
  ] = useState<string[]>([]);
  const [selectedCalendarCellListingIds, setSelectedCalendarCellListingIds] =
    useState<string[]>([]);
  const [selectedListingId, setSelectedListingId] = useState<string>();

  // derived state
  const scopedListings = useMemo(() => {
    const today = todayInTimezone(timezone);
    switch (pathname) {
      case OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH:
      case OPERATIONS_LISTINGS_CALENDAR_DRAFT_PATH:
        return fetchedListings;
      case OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH:
      case OPERATIONS_LISTINGS_CALENDAR_INACTIVE_PATH:
        return fetchedListings.filter(
          (l) => l.endDate != null && l.endDate < today,
        );
      case OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH:
      case OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH:
        return fetchedListings.filter(
          (l) => l.endDate == null || l.endDate >= today,
        );
      default:
        return [];
    }
  }, [fetchedListings, pathname]);
  const selectedListing = scopedListings.find(
    (listing) => listing.id === selectedListingId,
  );
  const pageListings = pathname.includes('floor-plan')
    ? scopedListings
    : getListingsForWeekByDate(scopedListings, selectedDate);

  const getListListings = () => {
    if (pathname.includes('floor-plan')) {
      if (selectedFloorPlanTableListingIds.length) {
        return pageListings.filter((listing) =>
          selectedFloorPlanTableListingIds.includes(listing.id),
        );
      }
    } else if (selectedCalendarCellListingIds.length) {
      return pageListings.filter((listing) =>
        selectedCalendarCellListingIds.includes(listing.id),
      );
    }
    return pageListings;
  };
  const listListings = getListListings();

  // handlers
  const clearSelectedListing = () => setSelectedListingId(undefined);
  const handleSetSelectedDate = (date: string) => {
    setSelectedFloorPlanTableListingIds([]);
    setSelectedDate(date);
  };

  const value = useMemo(
    (): ListingsContextState => ({
      clearSelectedListing,
      floorPlan: floorPlan as FloorPlanData,
      isLoading: isListingsLoading || isFloorPlanLoading,
      scopedListings,
      pageListings,
      listListings,
      refreshFloorPlan: fetchFloorPlan,
      refreshListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      setSelectedDate: handleSetSelectedDate,
      setSelectedFloorPlanTableListingIds,
      setSelectedListingId,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    }),
    [
      floorPlan,
      isFloorPlanLoading,
      isListingsLoading,
      scopedListings,
      pageListings,
      listListings,
      selectedDate,
      selectedFloorPlanTableListingIds,
      selectedListing,
      selectedCalendarCellListingIds,
      setSelectedCalendarCellListingIds,
    ],
  );

  return (
    <ListingsContext.Provider value={value}>
      {children}
    </ListingsContext.Provider>
  );
};

import { TZDateMini } from '@date-fns/tz';
import cx from 'classnames';
import { addDays, addWeeks, format, parseISO, startOfWeek } from 'date-fns';
import { Button, ButtonVariants } from '@components/button/Button';
import { IconButton } from '@components/iconButton/IconButton';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import styles from './WeekSelector.scss';

interface WeekSelectorProps {
  /** e.g. '2000-01-15' */
  selectedDate: string;
  onChangeSelectedDate: (date: string) => void;
}

export const WeekSelector = ({
  selectedDate,
  onChangeSelectedDate,
}: WeekSelectorProps) => {
  const { timezone } = useRestaurant();
  const weekStart = startOfWeek(parseISO(selectedDate));
  const weekEnd = addDays(weekStart, 6);
  const now = TZDateMini.tz(timezone);
  const weekIncludesToday = now >= weekStart && now < addDays(weekEnd, 1);

  const handleOnClickStep = (step: number) => {
    onChangeSelectedDate(
      toISODateFormat(addWeeks(parseISO(selectedDate), step)),
    );
  };

  const handleOnClickToday = () => {
    onChangeSelectedDate(toISODateFormat(now));
  };

  return (
    <nav aria-label="Week" className={styles.main}>
      <Button
        className={cx(typography.h8, styles.buttonToday)}
        label="Today"
        onClick={handleOnClickToday}
        isDisabled={weekIncludesToday}
        variant={ButtonVariants.Tertiary}
      />
      <IconButton
        ariaLabel="previous week"
        className={styles.button}
        iconName="arrowLeft"
        onClick={() => handleOnClickStep(-1)}
      />
      <div className={styles.text} data-testid="selected-week">
        {`${format(weekStart, 'PP')} – ${format(weekEnd, 'PP')}`}
      </div>
      <IconButton
        ariaLabel="next week"
        className={cx(styles.button, styles.arrowRightIcon)}
        iconName="arrowLeft"
        onClick={() => handleOnClickStep(1)}
      />
    </nav>
  );
};
